footer {
    color: white;
    background-color: black;
    text-align: center;
    margin-top: auto;
    height: 5vh;
}

#copyright {
    padding: 10px 0 10px 0;
    float: right !important;
}

@media screen and (max-width: 1024px){
    #copyright {
        float: none !important;
    }
}