header {
    color: white;
    background-color: black;
    display: flex;
}

h1 {
    margin: auto 0 auto 0;
}

.navigation {
    display: flex;
    background-color: black;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
}

.navigation a.active {
    color: rgba(221, 221, 221, 0.904);
}

.navigation a {
    color: white;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
}

@media screen and (max-width: 1024px){
    .menu-large {
        display: none !important;
    }

    .menu-small {
        display: flex;
    }
}

@media screen and (min-width: 1024px){
    .menu-small {
        display: none;
    }
}